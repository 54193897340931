import React, { useEffect, useState } from 'react'
import first from 'lodash/first'
import flatten from 'lodash/flatten'
import { Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { CONSULTATION_TYPES, ConsultationTypeData } from '../lib/modalityHelpers'
import { OrganizationsState, fetchPricesGroupsAction, organizationsSelector } from '../hasura/slices/organizations'
import { Table, Header, HeaderCell, Cell, Row } from '../components/common/Table'
import { getStatPrice, getStatTypeDescriptions } from '../lib/pricingHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

import {
  SpecialistPaymentsState,
  fetchSpecialistPaymentsAction,
  insertSpecialistPaymentAction,
  specialistPaymentsSelector,
  updateSpecialistPaymentAmountAction,
} from '../hasura/slices/specialistPayments'

interface NewSpecialistPayment {
  id?: number
  value: string
  parsedValue: number
  constultationTypeId: number
  statTypeId?: number
  valid: boolean
}

export default function PayRates() {
  const dispatch = useDispatch()

  const [newSpecialistPayment, setNewSpecialistPayment] = useState<NewSpecialistPayment | undefined>()

  const { priceGroups }: OrganizationsState = useSelector(organizationsSelector)
  const { accessToken, user, defaultTurnaroundTime }: UsersState = useSelector(usersSelector)
  const { specialistPayments }: SpecialistPaymentsState = useSelector(specialistPaymentsSelector)

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
    dispatch(fetchSpecialistPaymentsAction(accessToken, [user.organization.enterprise.id]))
  }, [accessToken, user])

  const prices = flatten(priceGroups?.map((p) => p.prices))
  const statTypeDescriptions = getStatTypeDescriptions(prices, defaultTurnaroundTime)
  const consultationTypes = CONSULTATION_TYPES.filter((c) => prices.some((p) => p.consultation_type_id === c.consultationTypeId))
  const specialistPaymentGroupId = first(user?.organization.enterprise.specialist_payment_groups)?.id

  const saveNewSpecialistPayment = async () => {
    if (!newSpecialistPayment || !user) return

    if (newSpecialistPayment.parsedValue && newSpecialistPayment.valid) {
      if (newSpecialistPayment.id) {
        await dispatch(
          updateSpecialistPaymentAmountAction(
            accessToken,
            newSpecialistPayment.id,
            newSpecialistPayment.parsedValue,
            user.organization.enterprise.id
          )
        )
      } else {
        await dispatch(
          insertSpecialistPaymentAction(
            accessToken,
            {
              amount: newSpecialistPayment.parsedValue,
              consultation_type_id: newSpecialistPayment.constultationTypeId,
              stat_type_id: newSpecialistPayment.statTypeId,
              specialist_payment_group_id: specialistPaymentGroupId,
            },
            user.organization.enterprise.id
          )
        )
      }
    }

    setNewSpecialistPayment(undefined)
  }

  const editableSpecialistPaymentCell = (params: {
    id?: number
    consultationType: ConsultationTypeData
    statTypeId?: number
    amount: number
  }) => {
    const key = `${params.consultationType}-${params.statTypeId || ''}`

    const isEditing = params.id
      ? newSpecialistPayment?.id === params.id
      : newSpecialistPayment?.constultationTypeId === params.consultationType.consultationTypeId &&
        newSpecialistPayment?.statTypeId === params.statTypeId

    return (
      <Cell key={key}>
        <div className="d-flex align-items-center position-relative py-1">
          $
          <Input
            className="ml-1 width-80px"
            id={`specialist-payment-${key}`}
            onBlur={saveNewSpecialistPayment}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') saveNewSpecialistPayment()
            }}
            value={isEditing ? newSpecialistPayment?.value : params.amount}
            onChange={(e: any) =>
              setNewSpecialistPayment({
                id: params.id,
                parsedValue: parseFloat(e.target.value),
                valid: !isNaN(parseFloat(e.target.value)),
                value: e.target.value,
                constultationTypeId: params.consultationType.consultationTypeId,
                statTypeId: params.statTypeId,
              })
            }
            onFocus={() =>
              setNewSpecialistPayment({
                constultationTypeId: params.consultationType.consultationTypeId,
                id: params.id!,
                parsedValue: 0,
                statTypeId: params.statTypeId,
                valid: true,
                value: '',
              })
            }
          />
        </div>
      </Cell>
    )
  }

  return (
    <Layout>
      <MainBox defaultPadding>
        <h4 className="bold">Pay Rates</h4>
        <p className="text-m text--gray6">Manage pay rates for your specialists.</p>

        <div className="my-4">
          <div className="my-4 p-4 border rounded">
            <div className="d-flex justify-content-between">
              <p className="text-m text--gray7 m-0">
                <span className="bold">GROUP 1</span>
              </p>
            </div>

            <Table>
              <thead>
                <tr>
                  <HeaderCell>
                    <Header>Type</Header>
                  </HeaderCell>

                  {statTypeDescriptions.map((type, idx) => (
                    <HeaderCell key={idx}>
                      <Header>{type}</Header>
                    </HeaderCell>
                  ))}
                </tr>
              </thead>

              <tbody>
                {consultationTypes.map((consultationType, idx) => (
                  <Row key={idx}>
                    <Cell>{consultationType.displayName}</Cell>

                    {statTypeDescriptions.map((statTypeDescription) => {
                      const modalityPrices = prices.filter((p) => p.consultation_type_id === consultationType.consultationTypeId)
                      const statPrice = getStatPrice(statTypeDescription, defaultTurnaroundTime, modalityPrices)
                      const statTypeId = statPrice?.stat_type?.id

                      if (!statTypeDescription?.includes('STANDARD') && !statPrice) {
                        return <Cell key={`${consultationType.consultationTypeId}-${statTypeDescription}`} />
                      }

                      const existingSpecialistPayment = specialistPayments.find(
                        (s) =>
                          !s.is_exotic &&
                          s.consultation_type_id === consultationType.consultationTypeId &&
                          s.stat_type_id == statTypeId
                      )

                      return editableSpecialistPaymentCell({
                        id: existingSpecialistPayment?.id,
                        consultationType,
                        statTypeId,
                        amount: existingSpecialistPayment?.amount || 0,
                      })
                    })}
                  </Row>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </MainBox>
    </Layout>
  )
}
